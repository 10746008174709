@media only screen and (min-width: 800px) {
  .reset {
    height: 100%;
    min-height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0078c1;
  }
  .reset__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: white;
    padding: 8%;
    width: 40%;
    height: 95%;
    clip-path: polygon(10% 0, 89% 4%, 93% 82%, 0% 100%);
  }
  .reset__textBox {
    padding: 2%;
    font-size: 18px;
    width: 100%;
    margin-bottom: 2%;
  }
  .reset__btn {
    padding: 2%;
    font-size: 18px;
    margin-bottom: 2%;
    width: 100%;
    border: none;
    color: white;
    background-color: #0078c1;
  }
  .reset div {
    margin-top: 7px;
  }
  .reset__logo {
    padding: 2%; 
    justify-self: center;
    margin-bottom: 5%;
    width: 30%;
    align-self: center;
  }
}
  @media only screen and (min-width: 600px) and (max-width: 800px) {
    .reset {
      height: 100%;
      min-height: 100%;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0078c1;
    }
    .reset__container {
      display: flex;
      flex-direction: column;
      text-align: center;
      background-color: white;
      padding: 12%;
      width: 60%;
      height: 95%;
      clip-path: polygon(10% 0, 89% 4%, 93% 82%, 0% 100%);
    }
    .reset__textBox {
      padding: 2%;
      font-size: 18px;
      width: 100%;
      margin-bottom: 2%;
    }
    .reset__btn {
      padding: 2%;
      font-size: 18px;
      margin-bottom: 2%;
      width: 100%;
      border: none;
      color: white;
      background-color: #0078c1;
    }
    .reset div {
      margin-top: 7px;
    }
    .reset__logo {
      padding: 2%; 
      justify-self: center;
      margin-bottom: 5%;
      width: 30%;
      align-self: center;
    }
  }

  @media only screen and (max-width: 599px) {
    .reset {
      height: 100%;
      min-height: 100%;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0078c1;
    }
    .reset__container {
      display: flex;
      flex-direction: column;
      text-align: center;
      background-color: white;
      padding: 7%;
      width: 100%;
      height: fit-content;
    }
    .reset__textBox {
      padding: 2%;
      font-size: 18px;
      width: 100%;
      margin-bottom: 2%;
    }
    .reset__btn {
      padding: 2%;
      font-size: 18px;
      margin-bottom: 2%;
      width: 100%;
      border: none;
      color: white;
      background-color: #0078c1;
    }
    .reset div {
      margin-top: 7px;
    }
    .reset__logo {
      padding: 2%; 
      justify-self: center;
      margin-bottom: 5%;
      width: 30%;
      align-self: center;
    }
  }